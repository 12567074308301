import React from 'react'
import { Box, Flex, Grid } from '@radix-ui/themes'
import Skeleton from 'react-loading-skeleton'
import styles from './setups-game-skeleton.module.scss'

export const SetupsGameSkeleton: React.FC = () => {
  return (
    <Flex
      className={styles.setupsContainer}
      direction={'column'}
      gap={'5'}
    >
      <Skeleton
        height={'6rem'}
        width={'32rem'}
      />
      <Grid
        gap='2'
        columns='1fr 1fr 1fr'
        width={'100%'}
        className={styles.gridWrapper}
      >
        <Box
          width={'100%'}
          height={'100%'}
        >
          <Skeleton
            width={'100%'}
            height={'55rem'}
            borderRadius={'5rem'}
          />
        </Box>
        <Box
          width={'100%'}
          height={'100%'}
        >
          <Skeleton
            width={'100%'}
            height={'55rem'}
            borderRadius={'5rem'}
          />
        </Box>
        <Box
          width={'100%'}
          height={'100%'}
        >
          <Skeleton
            width={'100%'}
            height={'55rem'}
            borderRadius={'5rem'}
          />
        </Box>
      </Grid>
    </Flex>
  )
}
