import { useAccount } from 'wagmi'
import { allowance, approve, estimateFee, getBalance, mintUsdc } from '../calls'
import { Web3Adress } from 'shared/types/web3'
import { wagmiConfig } from 'app/wagmi-config'

// TODO: rename to useUsdt or to useUsdcoin
export const useUsdc = () => {
  const account = useAccount({
    config: wagmiConfig
  })

  if (!account.address) return

  const getActualBalance = () => getBalance(account.address as Web3Adress)
  const mint = (amount: string) => mintUsdc(account, amount)
  const estimateGas = () => estimateFee(account)
  const getAllowence = () => allowance(account)
  const approveAmount = (amount: number) => approve(amount)

  return {
    approveAmount,
    getActualBalance,
    getAllowence,
    mint,
    estimateGas
  }
}
