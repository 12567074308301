import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { GetGlobalRoomQuery } from '__generated__/graphql'
import { GET_GLOBAL_ROOM_ID } from 'api/chat/get-global-room-id'
import { globalRoomIdVar } from '../store/global-room-id'

export const useGlobalRoomIdLoader = () => {
  const { data } = useQuery<GetGlobalRoomQuery>(GET_GLOBAL_ROOM_ID)

  useEffect(() => {
    if (!data?.getGlobalRoom) return

    globalRoomIdVar(data.getGlobalRoom.id)
  }, [data])
}
