import { useCallback, useState } from 'react'
import { useUsdc } from 'contracts/usdc'
import { showNotificationToast } from 'shared/utils/notify'

export const useApproveSubmit = () => {
  const [loading, setLoading] = useState(false)

  const usdc = useUsdc()

  const handleApprove = useCallback(
    async (amount: string) => {
      if (!usdc) return

      setLoading(true)
      showNotificationToast({
        title: 'Approve action in your wallet',
        type: 'info'
      })
      try {
        await usdc.approveAmount(Number(amount))
        showNotificationToast({
          title: `${amount}USDT approved`,
          type: 'success'
        })
      } catch (error) {
        showNotificationToast({
          title: `Something went wrong`,
          description: 'Please try again',
          type: 'success'
        })
      } finally {
        setLoading(false)
      }
    },
    [usdc, setLoading]
  )

  return { loading, handleApprove }
}
