import { readContract, writeContract } from '@wagmi/core'
import { wagmiConfig } from 'app/wagmi-config'
import { addressList } from 'contracts/address'
import { getTransactionStatus } from 'contracts/utils/get-transaction-status'
import { Web3Adress } from 'shared/types'
import { abi } from './abi'

export const deposits = (address: Web3Adress) =>
  readContract(wagmiConfig, {
    abi,
    functionName: 'deposits',
    address: addressList.treasury,
    args: [address]
  })

export const withdraw = async (amount: string) => {
  const result = await writeContract(wagmiConfig, {
    abi,
    address: addressList.treasury,
    args: [BigInt(amount)],
    functionName: 'withdraw'
  })

  return getTransactionStatus(result)
}
