import { Suspense, lazy } from 'react'
import { Head } from 'features/head'
import { useQueryParams } from 'shared/hooks/use-query'
import { OneVsOnePageSkeleton } from 'shared/skeletons/page-skeletons/one-vs-one-page-skeleton'
import { OneVsOneGameType } from '__generated__/graphql'
import {
  gameIdViewVar,
  gameTypeViewVar
} from 'features/mode-one-vs-one/store/game-view-store'

const OneVsOneMode = lazy(() => import('features/mode-one-vs-one'))

export const OneVsOneModePage: React.FC = () => {
  const query = useQueryParams()
  const gameId = query.get('gameId')
  const gameType = query.get('type') as OneVsOneGameType

  if (gameId && gameType) {
    gameIdViewVar(gameId)
    gameTypeViewVar(gameType)
  }

  return (
    <>
      <Head title='One versus One' />
      <Suspense fallback={<OneVsOnePageSkeleton />}>
        <OneVsOneMode />
      </Suspense>
    </>
  )
}
