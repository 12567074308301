import { Flex, Grid } from '@radix-ui/themes'
import { Head } from 'features/head'
import PriceGraph from 'features/price-graph'

export const DevPage: React.FC = () => {
  return (
    <>
      <Head title='Deposits' />
      <Flex
        style={{ minHeight: '87.5rem' }}
        height={'100%'}
        width={'100%'}
        direction='column'
      >
        {/* <PriceGraph assetId={'BTC'} /> */}
        <ColorGrid color='red' />
        <ColorGrid color='orange' />
        <ColorGrid color='yellow' />
        <ColorGrid color='green' />
        <ColorGrid color='cyan' />
        <ColorGrid color='blue' />
        <ColorGrid color='purple' />
        <ColorGrid color='pink' />
        <ColorGrid color='plum' />
        <ColorGrid color='violet' />
      </Flex>
    </>
  )
}

const ColorGrid = ({ color }: { color: string }) => {
  return (
    <Grid
      columns='repeat(12, 1fr)'
      height='6'
      data-accent-color={color}
    >
      <div style={{ backgroundColor: 'var(--accent-a1)' }}>1</div>
      <div style={{ backgroundColor: 'var(--accent-a2)' }}>2</div>
      <div style={{ backgroundColor: 'var(--accent-a3)' }}>3</div>
      <div style={{ backgroundColor: 'var(--accent-a4)' }}>4</div>
      <div style={{ backgroundColor: 'var(--accent-a5)' }}>5</div>
      <div style={{ backgroundColor: 'var(--accent-a6)' }}>6</div>
      <div style={{ backgroundColor: 'var(--accent-a7)' }}>7</div>
      <div style={{ backgroundColor: 'var(--accent-a8)' }}>8</div>
      <div style={{ backgroundColor: 'var(--accent-a9)' }}>9</div>
      <div style={{ backgroundColor: 'var(--accent-a10)' }}>10</div>
      <div style={{ backgroundColor: 'var(--accent-a11)' }}>11</div>
      <div style={{ backgroundColor: 'var(--accent-a12)' }}>12</div>
    </Grid>
  )
}
