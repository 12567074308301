import { useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useLoadTreasuryBalance } from 'contracts/treasury/hooks/use-load-treasury-balance'
import { globalOverlayTreasuryBalanceLoadingVar } from 'shared/store/global-overlay-state-store'
import { treasuryBalanceLoadingVar, treasuryBalanceVar } from '../store/balance'

export const useTreasuryLoader = () => {
  const { balance: loadedBalance, error, loading } = useLoadTreasuryBalance()

  const balance = useReactiveVar(treasuryBalanceVar)

  useEffect(() => {
    if (!loadedBalance) return

    treasuryBalanceVar(Number(loadedBalance))
  }, [loadedBalance])

  useEffect(() => {
    treasuryBalanceLoadingVar(loading)
    globalOverlayTreasuryBalanceLoadingVar(loading && !error)
  }, [loading, error])

  return {
    balance,
    loading,
    error: Boolean(error)
  }
}
