import { Suspense } from 'react'
import Onboarding from 'features/onboarding'
import { Head } from 'features/head'

export const OnboardingPage: React.FC = () => {
  return (
    <>
      <Head title='Onboarding' />
      <Suspense fallback={<p>Loading...</p>}>
        <Onboarding />
      </Suspense>
    </>
  )
}
