import React from 'react'
import { Flex, Text } from '@radix-ui/themes'
import { UserIcon } from 'shared/icons'
import { useOnlineUsers } from '../hooks/use-control-active-users'

export const ChatMobileHeader: React.FC = () => {
  const { activeUsersAmount } = useOnlineUsers()

  return (
    <Flex
      width={'100%'}
      justify={'between'}
      px={'6'}
      py={'4'}
    >
      <Text
        size={'5'}
        className='color-white'
      >
        Global Chat
      </Text>

      <Flex
        align={'center'}
        gap={'1'}
      >
        <Flex mb={'1'}>
          <UserIcon className='color-green' />
        </Flex>
        <Text
          className='color-green'
          size={'4'}
        >
          {activeUsersAmount}
        </Text>
      </Flex>
    </Flex>
  )
}
