import { useEffect, useState } from 'react'
import { useBalanceEvents } from './use-balance-events'
import { useUsdc } from './use-usdc'

export const useLoadBalance = () => {
  const [balance, setBalance] = useState<number | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<unknown | null>(null)

  // TODO: temprary disable subscription
  // const { event } = useBalanceEvents()

  const usdc = useUsdc()

  const loadBalance = async () => {
    if (!usdc) {
      setLoading(false)
      return
    }

    try {
      const result = await usdc.getActualBalance()
      setLoading(false)
      setBalance(Number(result) / Math.pow(10, 18))
    } catch (error) {
      setError(error)
    }
  }

  useEffect(() => {
    loadBalance()
  }, [usdc])

  // useEffect(() => {
  //   if (!event) return
  //   if (!loading) {
  //     loadBalance()
  //   }
  // }, [event])

  return {
    balance,
    error,
    loading
  }
}
