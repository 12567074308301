import { wagmiConfig } from 'app/wagmi-config'
import { Web3Adress } from 'shared/types'
import { useAccount } from 'wagmi'
import { deposits, withdraw } from '../calls'

export const useTreasury = () => {
  const account = useAccount({
    config: wagmiConfig
  })

  if (!account.address) return

  const loadTreasuryBalance = () => deposits(account.address as Web3Adress)
  const claimTreasury = (amount: string) => withdraw(amount)

  return {
    claimTreasury,
    loadTreasuryBalance
  }
}
