/* eslint-disable max-lines */
import { Head } from 'features/head'
import { TermsAndConditions } from 'features/terms-dialog/components/terms-and-conditions'

export const TermsPage: React.FC = () => {
  return (
    <>
      <Head title='Terms and Conditions' />
      <TermsAndConditions />
    </>
  )
}
