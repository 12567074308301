import { WSS_PROVIDER_URL, VITE_HTTP_PROVIDER } from 'app/constants'
import { createClient } from 'viem'
import { http, createConfig, webSocket } from 'wagmi'
import { arbitrumSepolia, mainnet } from 'wagmi/chains'

export const APP_CHAIN = arbitrumSepolia
const httpProvider = VITE_HTTP_PROVIDER || undefined

export const wagmiConfig = createConfig({
  chains: [APP_CHAIN, mainnet],
  transports: {
    [APP_CHAIN.id]: http(httpProvider),
    [mainnet.id]: http()
  }
  // client: ({ chain }) => createClient({ chain, transport: http() })
})

export const wagmiWsConfig = createConfig({
  chains: [APP_CHAIN, mainnet],
  client: ({ chain }) =>
    createClient({ chain, transport: webSocket(WSS_PROVIDER_URL) })
})
