import { useCallback, useState } from 'react'
import { useTreasury } from 'contracts/treasury/hooks/use-treasury'
import { showNotificationToast } from 'shared/utils/notify'

export const useClaimSubmit = () => {
  const [loading, setLoading] = useState(false)

  const treasury = useTreasury()

  const handleClaim = useCallback(
    async (amount: string) => {
      if (!treasury) return

      setLoading(true)

      showNotificationToast({
        title: 'Approve action in your wallet',
        type: 'info'
      })

      try {
        await treasury.claimTreasury(amount)
        showNotificationToast({
          title: `${amount}USDT claimed`,
          type: 'success'
        })
      } catch (error) {
        console.error(error)
        showNotificationToast({
          title: `Something went wrong`,
          description: 'Please try again',
          type: 'error'
        })
      } finally {
        setLoading(false)
      }
    },
    [treasury, setLoading]
  )

  return { handleClaim, loading }
}
