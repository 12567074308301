import {
  welcomeWindowStepOneBanner,
  welcomeWindowStepThreeBanner,
  welcomeWindowStepTwoBanner
} from 'shared/images'

export const WELCOME_WINDOW_STEPS_DATA = {
  step1: {
    index: 'STEP 1',
    image: welcomeWindowStepOneBanner,
    title: 'Get Arbitrum Sepolia ETH',
    description:
      'Arb ETH are required to cover transaction fees on the platform, such as for playing games.',
    secondaryTitle: 'How to get these tokens?',
    secondaryDescription:
      '1. Complete the first quest in the Rewards and receive Native Tokens as a reward.'
  },
  step2: {
    index: 'STEP 2',
    image: welcomeWindowStepTwoBanner,
    title: 'Mint USDT to Playing',
    description: 'USDT is the currency you will use to play in our Game Modes.',
    secondaryTitle: 'How to get these tokens?',
    secondaryDescription:
      'You can easily mint USDT on our XYRO platform in Balance section.'
  },
  step3: {
    index: 'STEP 3',
    image: welcomeWindowStepThreeBanner,
    title: 'Engage in Rewards, Play Games, Climb the Leaderboard',
    description:
      'The higher your ranking at the end of Testnet, the bigger your airdrop share!',
    secondaryTitle: 'How do you rise in the rankings?',
    secondaryDescription:
      'Complete tasks in Rewards to get more Xyro Points and climb the Leaderboard!'
  }
}
