import { Heading } from '@radix-ui/themes'
import { Head } from 'features/head'

export const NotFoundPage = () => (
  <>
    <Head title='404: Not found' />
    <Heading
      as='h1'
      size='8'
      mt='6'
    >
      404: Not Found
    </Heading>
  </>
)
