/* eslint-disable max-lines */
import { Suspense } from 'react'
import { Box, Heading, Text } from '@radix-ui/themes'
import { Head } from 'features/head'
import { PrivacyPolicy } from 'features/terms-dialog/components/privacy-policy'

export const PrivacyPolicyPage: React.FC = () => {
  return (
    <>
      <Head title='PrivacyPolicy' />
      <PrivacyPolicy />
    </>
  )
}
