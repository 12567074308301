import { Box } from '@radix-ui/themes'
import styles from './xyro-pulse-dot.module.scss'

export const XyroPulseDot = () => {
  return (
    <Box
      ml='2'
      className={styles.pulseDot}
    />
  )
}
