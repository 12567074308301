import { FC } from 'react'
import { useReactiveVar } from '@apollo/client'
import { TriangleDownIcon } from '@radix-ui/react-icons'
import { Flex } from '@radix-ui/themes'
import classnames from 'classnames'
import { useLocation } from 'react-router-dom'
import { ScullIcon } from 'shared/icons'
import { RadixText, XyroButton } from 'shared/ui'
import { getCurrentModeByPathName } from 'shared/utils/get-current-mode-by-pathname'
import { getClassNameByMode } from '../get-classname-by-mode'
import { getModeByName } from '../get-mode-by-name'
import { mobileNavToggleVar } from '../store/navigation-mobile'
import styles from '../navigation.module.scss'

export const ModeSelectionToggle: FC = () => {
  const { pathname } = useLocation()
  const isNavOpen = useReactiveVar(mobileNavToggleVar)

  const currentMode = getCurrentModeByPathName(pathname)
  const ModeIcon = currentMode ? getModeByName(currentMode)?.icon : ScullIcon

  const handleToggleClick = () => {
    mobileNavToggleVar(!isNavOpen)
  }

  const modeClassName = getClassNameByMode(currentMode)

  return (
    <XyroButton
      onClick={handleToggleClick}
      className={classnames(styles.modeSelectionToggle, {
        [styles.active]: isNavOpen
      })}
      shape='cutted-right'
      size='3'
      isWide
    >
      <Flex
        align='center'
        gap='2'
        py='3'
      >
        <ModeIcon
          height={'3rem'}
          width={'3rem'}
          className={modeClassName}
        />

        <RadixText
          size='1'
          weight={'medium'}
        >
          {currentMode || 'Game Modes'}
        </RadixText>

        <TriangleDownIcon
          color='var(--gray-9)'
          className={isNavOpen ? styles.toggleTriangleActive : ''}
        />
      </Flex>
    </XyroButton>
  )
}
