import { coinbaseWallet, metaMask, walletConnect } from 'wagmi/connectors'
import { WALLET_CONNECT_PROJECT_ID } from '../constants'
import { ConnectorProvider } from '../types'

export const getWalletConnectorProviders = (
  isMobile = false
): ConnectorProvider[] => {
  const walletConnectProvider = walletConnect({
    projectId: WALLET_CONNECT_PROJECT_ID
  })

  return [
    {
      id: 'io.metamask',
      name: 'Metamask',
      connector:
        isMobile ? walletConnectProvider : metaMask({ extensionOnly: true })
    },
    {
      id: 'coinbaseWalletSDK',
      name: 'Coinbase',
      connector: isMobile ? walletConnectProvider : coinbaseWallet()
    },
    {
      id: 'walletConnect',
      name: 'Wallet Connect',
      connector: walletConnectProvider
    }
  ]
}
