export type ShapeType = 'cutted-both' | 'cutted-left' | 'cutted-right'

/**
 * NOTE: function to define a proper svg-path for the xyro-button's shape
 */
export function getShapePathByType(shape: ShapeType, isWide: boolean) {
  const widthType = isWide ? 'wide' : 'square'
  const shapePath = XYRO_BUTTON_SHAPES[widthType][shape]
  return shapePath
}

const XYRO_BUTTON_SHAPES = {
  square: {
    'cutted-both':
      'M0 13.0212V29.6C0 31.8402 0 32.9603 0.435974 33.816C0.819467 34.5686 1.43139 35.1805 2.18404 35.564C3.03969 36 4.15979 36 6.4 36H22.4325C23.753 36 24.4132 36 25.0415 35.8638C25.5988 35.743 26.136 35.5433 26.637 35.2709C27.2017 34.9637 27.7017 34.5326 28.7017 33.6703L32.6692 30.249C33.8945 29.1925 34.5072 28.6642 34.9471 28.023C35.337 27.4549 35.6261 26.8239 35.8018 26.1576C36 25.4057 36 24.5967 36 22.9788V8.62311V5.32208C36 4.09262 36 3.47788 35.866 2.97326C35.4975 1.58598 34.414 0.502458 33.0267 0.134019C32.5221 0 31.9074 0 30.6779 0H13.5675C12.247 0 11.5868 0 10.9585 0.136202C10.4012 0.257014 9.86399 0.456656 9.36304 0.729102C8.79828 1.03625 8.29828 1.46741 7.29827 2.32973L7.29826 2.32974L3.33077 5.75095C2.10549 6.80753 1.49285 7.33581 1.05287 7.97695C0.662978 8.5451 0.373869 9.17615 0.198219 9.84244C0 10.5943 0 11.4033 0 13.0212Z',
    'cutted-left':
      'M0 13.8767V29.6C0 31.8402 0 32.9603 0.435974 33.816C0.819467 34.5686 1.43139 35.1805 2.18404 35.564C3.03969 36 4.15979 36 6.4 36H29.6C31.8402 36 32.9603 36 33.816 35.564C34.5686 35.1805 35.1805 34.5686 35.564 33.816C36 32.9603 36 31.8402 36 29.6V6.4C36 4.15979 36 3.03969 35.564 2.18404C35.1805 1.43139 34.5686 0.819467 33.816 0.435974C32.9603 0 31.8402 0 29.6 0H26.1161H13.9523C12.5023 0 11.7774 0 11.0942 0.16212C10.4884 0.305863 9.90877 0.543006 9.37596 0.865034C8.77504 1.22823 8.25794 1.73634 7.22376 2.75257L7.22375 2.75258L2.87149 7.02925L2.87148 7.02926C1.81213 8.07022 1.28245 8.5907 0.903477 9.2007C0.567498 9.7415 0.319734 10.3323 0.169481 10.951C0 11.6489 0 12.3915 0 13.8767Z',
    'cutted-right':
      'M36 22.1233L36 6.4C36 4.15979 36 3.03968 35.564 2.18404C35.1805 1.43139 34.5686 0.819466 33.816 0.43597C32.9603 -4.08043e-06 31.8402 -4.17836e-06 29.6 -4.3742e-06L6.4 -6.40241e-06C4.15979 -6.59826e-06 3.03969 -6.69618e-06 2.18404 0.435967C1.43139 0.819463 0.819469 1.43139 0.435973 2.18403C-9.33215e-07 3.03968 -1.03114e-06 4.15978 -1.22698e-06 6.39999L-3.25519e-06 29.6C-3.45104e-06 31.8402 -3.54896e-06 32.9603 0.43597 33.816C0.819466 34.5686 1.43139 35.1805 2.18404 35.564C3.03968 36 4.15979 36 6.4 36L9.88386 36L22.0477 36C23.4977 36 24.2226 36 24.9058 35.8379C25.5116 35.6941 26.0912 35.457 26.624 35.135C27.225 34.7718 27.7421 34.2637 28.7762 33.2474L28.7762 33.2474L33.1285 28.9707L33.1285 28.9707C34.1879 27.9298 34.7176 27.4093 35.0965 26.7993C35.4325 26.2585 35.6803 25.6677 35.8305 25.049C36 24.3511 36 23.6085 36 22.1233Z'
  },
  wide: {
    'cutted-both':
      'M0 12.0169V29.6C0 31.8402 0 32.9603 0.435974 33.816C0.819467 34.5686 1.43139 35.1805 2.18404 35.564C3.03969 36 4.15979 36 6.4 36H96.2391C97.8736 36 98.6908 36 99.4493 35.7981C100.121 35.6192 100.757 35.3248 101.329 34.928C101.973 34.4803 102.502 33.8571 103.559 32.6108L103.559 32.6108L105.72 30.0639C106.564 29.069 106.986 28.5716 107.287 28.0116C107.553 27.5148 107.749 26.9829 107.867 26.4316C108 25.8101 108 25.1578 108 23.8531V8.62312V6.4C108 4.15979 108 3.03969 107.564 2.18404C107.181 1.43139 106.569 0.819467 105.816 0.435974C104.96 0 103.84 0 101.6 0H92.0338H11.553C9.86874 0 9.02663 0 8.24842 0.213159C7.55898 0.402004 6.90883 0.712531 6.32864 1.1301C5.67374 1.60143 5.14448 2.25643 4.08595 3.56644L2.133 5.98336L2.133 5.98337C1.34257 6.96158 0.947363 7.45068 0.666256 7.99631C0.416865 8.48038 0.234531 8.99614 0.124277 9.52939C0 10.1305 0 10.7593 0 12.0169Z',
    'cutted-left':
      'M108 29.6L108 6.4C108 4.15979 108 3.03968 107.564 2.18404C107.181 1.43139 106.569 0.819466 105.816 0.435971C104.96 -3.42242e-06 103.84 -3.27787e-06 101.6 -2.98876e-06L11.7781 8.603e-06C9.91895 8.84292e-06 8.98939 8.96289e-06 8.14406 0.254251C7.39564 0.479341 6.69839 0.848482 6.09152 1.34093C5.40606 1.89715 4.88352 2.66594 3.83844 4.20353L1.66035 7.40807C1.04338 8.3158 0.734892 8.76966 0.51628 9.26202C0.322241 9.69901 0.181136 10.1576 0.095961 10.6281C-1.47118e-06 11.1582 -1.43868e-06 11.707 -1.37368e-06 12.8046L-5.83387e-07 26.1491L-3.7902e-07 29.6C-2.46351e-07 31.8402 -1.80016e-07 32.9603 0.435974 33.816C0.819466 34.5686 1.43139 35.1805 2.18404 35.564C3.03969 36 4.15979 36 6.4 36L6.69553 36L101.209 36L101.6 36C103.84 36 104.96 36 105.816 35.564C106.569 35.1805 107.181 34.5686 107.564 33.816C108 32.9603 108 31.8402 108 29.6Z',
    'cutted-right':
      'M0 6.4V29.6C0 31.8402 0 32.9603 0.435974 33.816C0.819467 34.5686 1.43139 35.1805 2.18404 35.564C3.03969 36 4.15979 36 6.4 36H96.2219C98.081 36 99.0106 36 99.8559 35.7458C100.604 35.5207 101.302 35.1515 101.908 34.6591C102.594 34.1029 103.116 33.3341 104.162 31.7965L106.34 28.5919C106.957 27.6842 107.265 27.2304 107.484 26.738C107.678 26.301 107.819 25.8424 107.904 25.3719C108 24.8418 108 24.293 108 23.1955V9.85088V6.4C108 4.15979 108 3.03969 107.564 2.18404C107.181 1.43139 106.569 0.819467 105.816 0.435974C104.96 0 103.84 0 101.6 0H101.304H6.79111H6.4C4.15979 0 3.03969 0 2.18404 0.435974C1.43139 0.819467 0.819467 1.43139 0.435974 2.18404C0 3.03969 0 4.15979 0 6.4Z'
  }
}
