import { Link } from '@radix-ui/themes'
import { Text } from '@radix-ui/themes'

import { LinkIcon } from 'shared/icons'
import { addressList } from 'contracts/address'

import styles from '../balance-add.module.scss'

export const MintDialogLink = () => (
  <Link
    className={styles.mintContractLink}
    target={'_blank'}
    href={`https://sepolia.arbiscan.io/address/${addressList.usdc}#code`}
  >
    <Text>View contract</Text>
    <LinkIcon />
  </Link>
)