import { FC, MouseEventHandler, useCallback } from 'react'
import { Button, Flex } from '@radix-ui/themes'
import {
  ChatIcon,
  HomeRoundedIcon,
  NotificationBellFilledIcon
} from 'shared/icons'
import { XyroButton } from 'shared/ui'
import { XyroLink } from 'shared/ui/xyro-link'
import { MENU_ICON_SIZE } from '../constants'
import { ModeSelectionToggle } from './navigation-mobile-mode-selection-toggle'
import styles from '../navigation.module.scss'

export const NavigationMobileMenu: FC = ({}) => {
  return (
    <Flex
      align={'center'}
      justify={'start'}
      gap='3'
    >
      <Flex>
        <HomeLink />
        <ModeSelectionToggle />
      </Flex>

      {/* NOTE: this buttons temporary postponed until features will be required on the mobile layout */}
      {/* <Flex gap='1'>
        <NotificationsButtos />
        <ChatButton />
      </Flex> */}
    </Flex>
  )
}

const HomeLink: FC = () => {
  return (
    <XyroLink to='/'>
      <XyroButton
        shape='cutted-left'
        isIconOnly
        size='3'
        className={styles.homeButton}
      >
        <HomeRoundedIcon
          width={MENU_ICON_SIZE}
          height={MENU_ICON_SIZE}
          color='var(--gray)'
        />
      </XyroButton>
    </XyroLink>
  )
}

// TODO: implement link to notifications
const NotificationsButtos: FC = () => {
  const handleNotificationClick = useCallback<
    MouseEventHandler<HTMLButtonElement>
  >(event => {
    throw new Error('Function not implemented.')
  }, [])

  return (
    <Button
      onClick={handleNotificationClick}
      variant='surface'
      className={styles.navButton}
    >
      <NotificationBellFilledIcon
        color='var(--gray)'
        height={MENU_ICON_SIZE}
        width={MENU_ICON_SIZE}
      />
    </Button>
  )
}

const ChatButton: FC = () => {
  // TODO: implement chat sidebar and toggling

  const handleChatClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    event => {
      throw new Error('Function not implemented.')
    },
    []
  )

  return (
    <Button
      onClick={handleChatClick}
      variant='surface'
      className={styles.navButton}
    >
      <ChatIcon
        height={MENU_ICON_SIZE}
        width={MENU_ICON_SIZE}
        color='var(--gray)'
      />
    </Button>
  )
}
