/* eslint-disable max-statements, max-lines */
import { useEffect, useState } from 'react'
import { useQuery, useReactiveVar, useSubscription } from '@apollo/client'
import {
  GameResultNotification,
  NotificationsSubscription,
  User
} from '__generated__/graphql'
import { GET_NOTIFICATIONS } from 'api/general/get-notifications'
import { SUBSCRIPTION_GLOBAL_NOTIFICATIONS } from 'api/general/subscription-global-notifications'
import {
  notificationListVar,
  notificationsSkipAmountVar
} from 'shared/store/notification'
import { useReadGlobalNotifications } from './use-read-global-notification'
import { useShowGlobalNotifications } from './use-show-global-notifications'
import { useUnreadGlobalNotifications } from './use-unread-global-notifications'

const DEFAULT_TAKE_NOTIFICATIONS_AMOUNT = 15

// TODO: Fix first level (this hook level) typing issue. GameType should be replaced with common notification type
export const useControlNotifications = (
  user: User | null,
  isNotificationListOpen = false
) => {
  const currentNotifications = useReactiveVar(notificationListVar)
  const notificationsSkipAmount = useReactiveVar(notificationsSkipAmountVar)

  const [lastNotification, setLastNotification] =
    useState<GameResultNotification | null>(null)

  const { handleMountNotification } = useShowGlobalNotifications()
  const { handleReadNotifications } = useReadGlobalNotifications(
    isNotificationListOpen
  )
  const {
    refetchUnreadNotifications,
    setUnreadNotificationsAmount,
    unreadNotificationsAmount
  } = useUnreadGlobalNotifications()

  const {
    data: notificationsQuery,
    loading,
    refetch: refetchNotificationList
  } = useQuery(GET_NOTIFICATIONS, {
    variables: {
      data: {
        take: DEFAULT_TAKE_NOTIFICATIONS_AMOUNT,
        skip: notificationsSkipAmount
      }
    },
    skip: !isNotificationListOpen
  })

  const { data } = useSubscription<NotificationsSubscription>(
    SUBSCRIPTION_GLOBAL_NOTIFICATIONS,
    { shouldResubscribe: true, variables: { userId: user?.id } }
  )

  useEffect(() => {
    const notificatons: GameResultNotification[] =
      notificationsQuery?.getNotifications?.notifications

    if (!notificatons) return

    const filteredNotifications = notificatons.filter(
      notification =>
        !currentNotifications.some(
          existingNotification => notification.id === existingNotification.id
        )
    )

    if (filteredNotifications.length === 0) return

    const newNotifications = [...currentNotifications, ...filteredNotifications]

    notificationListVar(newNotifications)

    const newNotificationIDs = notificatons?.map(el => {
      return el.id
    })

    handleReadNotifications(newNotificationIDs, newNotifications)
  }, [notificationsQuery])

  useEffect(() => {
    const notification = data?.notifications as GameResultNotification

    if (!notification) return

    const isNotificationExists = currentNotifications.find(
      el => el.id === notification?.id
    )

    if (isNotificationExists || notification.id === lastNotification?.id) return

    handleMountNotification(notification)

    setLastNotification(notification)

    if (currentNotifications.length === 0) {
      refetchUnreadNotifications()
    } else {
      notificationListVar([notification, ...currentNotifications])
      setUnreadNotificationsAmount(prev => prev + 1)
    }
  }, [
    data,
    handleMountNotification,
    refetchNotificationList,
    refetchUnreadNotifications
  ])

  useEffect(() => {
    if (user) return
    refetchNotificationList()
    refetchUnreadNotifications()
  }, [user, refetchNotificationList, refetchUnreadNotifications])

  return {
    loading,
    unreadNotificationsAmount,
    refetchNotificationList,
    refetchUnreadNotifications
  }
}
