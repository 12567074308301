import { Suspense } from 'react'
import { Flex, Heading, Text } from '@radix-ui/themes'
import { Head } from 'features/head'

export const AboutPage: React.FC = () => {
  return (
    <>
      <Head title='About' />
      <Suspense fallback={<p>Loading...</p>}>
        <Flex
          direction={'column'}
          gap={'4'}
          className='about-us-container'
        >
          <Heading
            as='h1'
            size='8'
            className='color-white'
          >
            About our platform
          </Heading>

          <Text className='color-white'>
            Welcome to our Platform! XYRO is an AI-powered, gamified social
            trading platform where users can engage in crypto-related game
            modes, test their skills, win rewards, compete with others, and
            share trading ideas—all without the involvement of fiat money. The
            platform offers a variety of game modes designed to challenge users
            in different types of crypto battles available on the Platform.
          </Text>

          <Text className='color-white'>
            You must be at least 18 years old (or the age of majority in your
            jurisdiction) to access, use, or register with the Platform. We may
            suspend your access to the Platform if you are a resident or a
            citizen of the USA, EU, or other countries or territories under the
            sanctions of the United Nations or the European Union.
          </Text>

          <Text className='color-white'>
            We grant you a limited, non-exclusive, non-transferable,
            non-assignable license to access and make personal and
            non-commercial use of the downloadable software included in the
            Platform. You are not allowed to resell or commercially use any part
            of the Platform or its contents and materials. All content and
            materials provided on XYRO, including but not limited to logos,
            graphics, text, software, and any other assets, are our intellectual
            property and are protected by applicable copyright, trademark, and
            other intellectual property laws. You acknowledge and agree that you
            do not acquire any ownership rights to the content and materials
            provided on the Platform.
          </Text>

          <Text className='color-white'>
            We are not responsible for any actions, transactions, or
            interactions between you and other users on the Platform. We are not
            liable for any damages, losses, or injuries resulting from
            interactions with third-party websites, applications, or materials
            accessed through our Platform. We provide you access to the Platform
            on an “as is” basis without warranties, and you engage with third
            parties and our Platform at your own risk. You acknowledge and
            accept these limitations of liability when accessing the Platform.
          </Text>
        </Flex>

        {/* TODO: Add PrivacyPolicy content */}
      </Suspense>
    </>
  )
}
