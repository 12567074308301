/* eslint-disable max-lines */
import { Box, Heading } from '@radix-ui/themes'

export const PrivacyPolicy = () => {
  return (
    <Box px='9'>
      <Heading
        as='h1'
        size='8'
        mt='6'
      >
        PRIVACY POLICY
      </Heading>
      <Heading
        as='h2'
        mt='5'
        mb='4'
        size='7'
      >
        INTRODUCTION
      </Heading>
      This Privacy Policy explains how Xyro (“Xyro” or “we” “us,” or “our”)
      collects, uses, shares, and otherwise processes your personal data and
      your rights regarding your personal data. We provide you with our website
      https://xyro.io/ (“Service”). When you use the Service or interact with us
      in any way (for example, by communicating via email), you may provide us
      with your personal data. This Privacy Policy explains how we handle and
      protect your personal data. “Personal data” means information that relates
      to, describes, or identifies you, including but not limited to your name,
      date of birth, or email. This Privacy Policy applies to your use of the
      Service. If you access or use the Service, you agree with and accept this
      Privacy Policy.
      <Heading
        as='h2'
        mt='5'
        mb='4'
        size='7'
      >
        INFORMATION WE MAY COLLECT
      </Heading>
      <Heading
        as='h3'
        mt='4'
        mb='3'
        size='5'
      >
        Information You Provide to Us
      </Heading>
      When you use or access the Service, create an account, send a message to
      us, or participate in activities on the Service, you may input personal
      data into the Service. We collect the following categories of data that
      you voluntarily provide to us:
      <ul>
        <li>
          Profile information, such as full name, username and avatar, date of
          birth, email, mailing address, telephone number.
        </li>

        <li>
          When using the Service, you may provide personal data via Twitter,
          email, Discord, or wallet login. This includes usernames, email
          addresses, profile information, and wallet details. Your data is
          collected and processed according to our Privacy Policy to ensure its
          security.{' '}
        </li>

        <li>
          Commercial activity such as trading activity, order activity,
          deposits, withdrawals, and account balances.
        </li>

        <li>
          Other information that you enter, post, send, or submit to our Service
          (photos and videos, account settings, account passwords,
          third-party-service username and password used to sign in to the
          Service, or information contained in the communications that you send
          to us by email, mail, or in any other form).
        </li>

        <li>
          To access certain aspects of the Service, the Users are required to
          provide a blockchain wallet address. We consider the blockchain wallet
          address, on its own, as not personally identifiable information.
          However, it may become associated with other information provided by
          the Users or generated during the use of our Service.
        </li>
      </ul>
      <Heading
        as='h3'
        mt='4'
        mb='3'
        size='5'
      >
        Information Collected from Third Parties
      </Heading>
      We may receive information about you or related to you or your wallet
      address from service providers, other Users, third-party services, public
      social media platforms, or public databases. For example, we may receive
      your blockchain wallet address and certain additional information from
      third-party wallet providers with whom you choose to share such details.
      <Heading
        as='h3'
        mt='4'
        mb='3'
        size='5'
      >
        Information Collected Automatically
      </Heading>
      Certain information is collected automatically when you use or visit our
      Service. This information does not reveal your identity (such as your name
      or contact information) but may include information about your device, its
      use, and other technical information. This information helps us maintain
      security, ensure optimization of the Service for your needs, and
      facilitate customer support. The tracking technologies we use to collect
      this information include cookies, web beacons, Flash cookies, embedded
      scripts, location-identifying technologies, pixels, and other similar
      technologies. The tracking technologies automatically collect the
      following categories of data:
      <ul>
        <li>
          Browser data: information that your browser automatically sends when
          you use the Service, including IP address, browser type, browser
          language, settings, blockchain wallet address, wallet type, and
          Service usage patterns. You have the option to configure your browser
          settings to reject all cookies or to notify you when a cookie is being
          sent. However, please note that if you decline cookies, certain parts
          of our Service may not be accessible to you.
        </li>
        <li>
          Device data: information about the device you use, such as device
          type, model and manufacturer, device operating system brand and model,
          device hard disk drive, operating system version, and information
          regarding the location of your device.
        </li>
        <li>
          Location tracking: We collect data that pinpoints your location using
          GPS, Wi-Fi, or cell-site triangulation. This helps us prevent fraud
          and manage risks effectively.
        </li>
        <li>
          Usage data: information about your use of the Service, including
          patterns, time, and date of your use of the trends for the Service.
        </li>
        <li>
          Service. Analytics data: general information about traffic and usage
        </li>
      </ul>
      <Heading
        as='h2'
        mt='5'
        mb='4'
        size='7'
      >
        PURPOSES FOR WHICH WE USE PERSONAL DATA
      </Heading>
      We use the personal data collected through our Service to:
      <ul>
        <li>
          provide the Service to you, create and maintain your account, verify
          your identity, process transactions, and provide customer support;
        </li>
        <li>
          improve the Service for you, identify activity patterns and usage
          trends;
        </li>
        <li>
          communicate with you, send you emails, including confirmations and
          reminders, respond to your requests for assistance or report on
          technical issues;
        </li>
        <li>
          comply with applicable laws, cooperate with investigations by law
          enforcement or other authorities of suspected violations of law,
          and/or to protect our and our Affiliates&apos;legal rights;
        </li>
        <li>
          update you about changes to the Service, send messages about new
          features or promotions that may interest you;
        </li>
        <li>
          or maintain safety and security, detect, prevent, and respond to
          potential or actual security incidents, and protect against other
          illegal activities.
        </li>
      </ul>
      <Heading
        as='h2'
        mt='5'
        mb='4'
        size='7'
      >
        HOW WE DISCLOSE PERSONAL DATA
      </Heading>
      <Heading
        as='h3'
        mt='4'
        mb='3'
        size='5'
      >
        Disclosure of Personal Data to Third Parties
      </Heading>
      We do not sell, share, or otherwise disclose your personal data, but in
      the following circumstances, we may share your personal data with the
      following third parties:
      <ul>
        <li>
          Public authorities and third parties when the law or a government
          order requires us to do so or when we need to protect our rights or
          users’ rights or prevent crime.
        </li>
        <li>
          Our subsidiaries, affiliates, and co-owners. We may also disclose
          personal data to operators of the Service, subsequent owners, and
          their advisors in connection with a merger, reorganization, sale of
          our assets, or other change of control.
        </li>
        <li>
          Service providers when they need access to your personal data to
          perform services for us, such as web hosting, debugging services,
          payment processing, email and productivity services, survey providers,
          and customer service providers.
        </li>
        <li>
          We may display or share information relating to your public activity
          on blockchains. For example, we may use APIs that make certain
          information, including your blockchain activity, available to
          websites, apps, and others.
        </li>
      </ul>
      <Heading
        as='h3'
        mt='4'
        mb='3'
        size='5'
      >
        Third-Party Websites
      </Heading>
      Our Service may include links to external websites or services that are
      not operated by us. If you click on a third-party link, you will be
      redirected to their website. The collection and use of your personal
      information by these third-party websites are governed by their privacy
      policies. Please note that we have no control over and assume no
      responsibility for the content, privacy policies, or practices of any
      third-party websites or services. It is your responsibility to familiarize
      yourself with the privacy practices of those third parties before
      interacting with their websites or services.
      <Heading
        as='h3'
        mt='4'
        mb='3'
        size='5'
      >
        Third-Party Waller Providers
      </Heading>
      We may disclose personal information associated with the blockchain wallet
      address to third-party wallet providers that assist us in providing the
      Service. These third-party wallet providers may provide us with your
      blockchain wallet address and certain other information you choose to
      share with them. The collection and use of your personal information by
      these third-party wallet providers are governed by their privacy policies.
      <Heading
        as='h3'
        mt='4'
        mb='3'
        size='5'
      >
        Disclosure of Anonymous Information
      </Heading>
      We may disclose anonymous information to any third party without
      notification. Anonymous information means information that does not allow
      the identification of an individual user, such as aggregated, anonymized,
      or de-identified information about the use of our services. Such
      information does not constitute personal data.
      <Heading
        as='h2'
        mt='5'
        mb='4'
        size='7'
      >
        YOUR RIGHTS TO PERSONAL DATA
      </Heading>
      You have the following rights to your
      <ul>
        <li>
          The right to know what data we have about you. To exercise this right,
          please contact us at support@xyro.io.
        </li>
        <li>
          The right to correct, delete, or update your personal data. You can
          access and modify your personal data through your account or the
          settings section of the Service. If you need assistance, please
          contact us at support@xyro.io. Please note that sometimes we may not
          be able to modify or delete certain information. For example, we are
          required by law to keep certain information for a defined period.
        </li>
        <li>
          The right to opt out of receiving electronic marketing communications
          from us. We may periodically send you emails and messages promoting
          the use of the Service. You can opt-out (unsubscribe) of receiving
          these messages by following the instructions included in each email or
          message. If you have any additional questions, please contact us at
          support@xyro.io. If you believe that we have violated your rights,
          please reach out to us at support@xyro.io. We value your feedback and
          are committed to resolving any issues or disputes in a prompt and
          informal manner.
        </li>
      </ul>
      <Heading
        as='h2'
        mt='5'
        mb='4'
        size='7'
      >
        DATA SECURITY
      </Heading>
      We have put in place security measures to protect your personal data from
      misuse, unauthorized access, disclosure, alteration, and destruction. We
      maintain appropriate administrative, technical, and physical safeguards.
      This includes firewalls, password protection, and other authentication
      controls. In addition, the browsing mode for the Service is protected by
      encryption technology. However, security on the internet can never be 100%
      guaranteed. We will take reasonable technical and organizational
      precautions to protect your personal data. You are responsible for
      securing your digital wallet, and we strongly recommend taking steps to
      ensure its ongoing security. If you encounter any issues with your wallet,
      please contact your wallet provider for assistance.
      <Heading
        as='h2'
        mt='5'
        mb='4'
        size='7'
      >
        INTERNATIONAL TRANSFERS OF PERSONAL DATA
      </Heading>
      Your personal data might be moved to and stored on computers situated
      outside of your state, province, country, or other governmental
      jurisdiction, where data protection laws could vary from those in your
      jurisdiction. By utilizing the Services, you agree to this transfer of
      your data beyond your country of residence, where data protection
      regulations may differ. Under certain circumstances, courts, law
      enforcement, regulatory agencies, or security entities in those foreign
      jurisdictions may have the right to access your personal data. We will use
      appropriate safeguards for transferring your personal data outside of your
      country of residence where required and only transfer such personal data
      under a legally valid transfer mechanism.
      <Heading
        as='h2'
        mt='5'
        mb='4'
        size='7'
      >
        CHILDREN’S PRIVACY
      </Heading>
      To register with and use the Service, individuals must be at least 18
      years of age. Our Service is not intended for use by children under the
      age of 18, and we have no intention of collecting personal data from
      individuals in this age group. Furthermore, the Company is committed to
      not knowingly collecting Personal Data from individuals under the
      specified age limit. In cases where specific countries impose a higher age
      of consent for the collection of Personal Data, the Company will
      necessitate parental consent before any Personal Data associated with a
      child in that jurisdiction is collected. We strongly encourage parents to
      educate their children about the importance of never sharing personal
      information online. Moreover, you are solely responsible for verifying
      compliance with the age requirements set by the Platform.
      <Heading
        as='h2'
        mt='5'
        mb='4'
        size='7'
      >
        CHANGES TO THE PRIVACY POLICY
      </Heading>
      This Privacy Policy can be updated from time to time. Please check back
      periodically for the latest version of the Privacy Policy, since you are
      solely responsible for tracking its changes. If there are any significant
      changes made to the use of your personal data, we will notify you. Your
      use of the Service following any changes to this Privacy Policy
      constitutes your acceptance of any such changes.
      <Heading
        as='h2'
        mt='5'
        mb='4'
        size='7'
      >
        QUESTIONS OR CONCERNS?
      </Heading>
      If you have any questions about this Privacy Policy, your privacy, or the
      protection of the personal data we hold about you, you can contact us at
      support@xyro.io.
    </Box>
  )
}
