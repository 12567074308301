import { ElementRef, forwardRef, memo } from 'react'
import { Avatar, Flex, Text } from '@radix-ui/themes'
import classnames from 'classnames'
import { XyroBox } from '../xyro-box/xyro-box'
import { USER_LEVER_MOCK } from './mocks'
import styles from './xyro-avatar.module.scss'

interface Props extends React.ComponentPropsWithoutRef<typeof Avatar> {
  src?: string
  displayLevelAndBorder?: boolean
  onClick?: React.MouseEventHandler<HTMLImageElement>
}
const XyroAvatarBase = forwardRef<ElementRef<typeof Avatar>, Props>(
  (
    {
      src,
      displayLevelAndBorder = true,
      size = '4',
      fallback,
      onClick,
      ...props
    },
    ref
  ) => {
    return (
      <Flex
        position={'relative'}
        onClick={onClick}
      >
        <Avatar
          src={src}
          fallback={fallback}
          size={size}
          ref={ref}
          variant={'solid'}
          color={'violet'}
          {...props}
        />

        {displayLevelAndBorder ? (
          <Flex
            className={styles.levelContainer}
            align={'center'}
            justify={'center'}
          >
            <XyroBox
              color='pink'
              size='4'
            >
              <Text
                align={'center'}
                className={classnames(styles.levelContainerText, 'color-white')}
                weight={'bold'}
                size={'1'}
              >
                {USER_LEVER_MOCK}
              </Text>
            </XyroBox>
          </Flex>
        ) : null}
      </Flex>
    )
  }
)

export const XyroAvatar = memo(
  XyroAvatarBase,
  (prevProps: Props, nextProps: Props) => prevProps.src === nextProps.src
)
