import {
  DoorOutIcon,
  TransactionsIcon,
  SettingIcon,
  UserIcon
} from 'shared/icons'

export const PROFILE_LINK_ICONS = {
  PROFILE_ICON: UserIcon,
  LOGOUT_ICON: DoorOutIcon,
  TRANSACTIONS_ICON: TransactionsIcon,
  SETTINGS_ICON: SettingIcon
}
