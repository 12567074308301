import { useState } from 'react'
import * as ToggleGroup from '@radix-ui/react-toggle-group'
import { Button, Flex, Heading, Text } from '@radix-ui/themes'
import cn from 'classnames'
import { MODES } from 'shared/constants'
import { GameModeType } from 'shared/types'
import { ContactUs, ModeOnboarding } from 'shared/ui'
import { ONBOARDING_GAME_MODES } from '../constants'
import styles from '../onboarding.module.scss'

export const Onboarding: React.FC = () => {
  const [currentMode, setCurrentMode] = useState<GameModeType>('bullsEye')

  const LogoIcon = ONBOARDING_GAME_MODES?.[currentMode]?.logo

  return (
    <>
      <Flex
        direction={'column'}
        gap={'3'}
        align={{ initial: 'center', sm: 'start' }}
      >
        <Heading
          as='h1'
          size='8'
          mt={{ initial: '1', sm: '6' }}
          className='color-white'
        >
          Onboardings
        </Heading>

        <ToggleGroup.Root
          type='single'
          asChild
          defaultValue={MODES[0]}
        >
          <Flex gap='2'>
            {MODES.map(mode => {
              const title = ONBOARDING_GAME_MODES?.[mode]?.title

              return (
                <ToggleGroup.Item
                  key={mode}
                  value={mode}
                  onClick={() => setCurrentMode(mode)}
                  asChild
                >
                  <Button
                    variant='soft'
                    size='4'
                    color='gray'
                    className={cn(styles.toggleButton, {
                      [styles.active]: currentMode === mode
                    })}
                  >
                    <Text weight={'bold'}>{title}</Text>
                  </Button>
                </ToggleGroup.Item>
              )
            })}
          </Flex>
        </ToggleGroup.Root>
      </Flex>
      <ModeOnboarding
        mode={currentMode}
        isOpen={true}
        headingIcon={<LogoIcon />}
      />
      <ContactUs />
    </>
  )
}
