/* eslint-disable max-lines */
import { Box, Heading } from '@radix-ui/themes'

export const TermsAndConditions = () => {
  return (
    <Box px='9'>
      <Heading
        as='h1'
        size='8'
        my='6'
      >
        TERMS AND CONDITIONS
      </Heading>
      <Heading
        as='h2'
        mt='5'
        mb='4'
        size='7'
      >
        INTRODUCTION
      </Heading>
      Xyro (“xyro” or “Company”, “we” “us,” or “our”) and users (“User” or
      “Users”, “you” or “your”) welcomes you to use our platform located at
      https://xyro.io/ (“Platform”). These Terms of Use (“Terms”) describe the
      terms and conditions that govern your use of or access to our Platform.
      The Terms constitute an agreement between you and us, so please read these
      Terms carefully. By accessing or using the Platform, you agree to be bound
      by these Terms, our Privacy Policy (https://xyro.io/privacy-policy), and
      the other rules provided with the Platform, whether or not you are a
      registered User of our Platform. If you do not accept or understand the
      Terms, please do not use or access the Platform.
      <Heading
        as='h3'
        mt='4'
        mb='3'
        size='5'
      >
        Changes to the Terms
      </Heading>
      We reserve the right at any time to: modify, update, or change the terms
      and conditions of the Terms or our Privacy Policy; modify, update, or
      change the Platform, including eliminating or discontinuing any content or
      feature of the Platform; or impose fees, charges or other conditions for
      use of the Platforms. Please check back periodically for the latest
      version of the Terms, since you are solely responsible for tracking its
      changes. If there are any significant changes made to the Terms, we will
      notify you. Your use of the Platform following any changes to these Terms
      constitutes your acceptance of any such changes.
      <Heading
        as='h2'
        mt='5'
        mb='4'
        size='7'
      >
        ABOUT OUR PLATFORM
      </Heading>
      Welcome to our Platform! Xyro is a gamified social trading platform
      without barriers to the Web2 audience where Users can test their ability
      to predict cryptocurrency prices and win rewards, compete with others and
      with the Platform, and share trading ideas. The Platform offers a variety
      of game modes designed to challenge users’ prediction skills, including
      “Up or Down,” “Eye,” “Setup,” “1vs1,” and others that we may implement or
      deactivate from time to time. Rules of each game will be disclosed on the
      Platform and you are responsible for checking it when you enter each game.
      <Heading
        as='h3'
        mt='4'
        mb='3'
        size='5'
      >
        Commission Fees
      </Heading>
      For most games offered on the Platform, a nominal commission fee of 1% is
      applied to the pool size. However, the final commission fee will be
      determined at the start. While the margin will be set at 1%, it’s also
      necessary to cover transaction costs for opening and closing games.
      Therefore, the final commission will depend on the network. If no bets are
      placed, the game will be closed, and bets will be returned to users. The
      percentage depends on transaction prices within the network for starting
      and closing games.
      <Heading
        as='h2'
        mt='5'
        mb='4'
        size='7'
      >
        Price Accuracy
      </Heading>
      To ensure accurate price data, our platform uses Chainlink, a trusted
      decentralized oracle network. This allows us to access verified price
      feeds from reliable sources, improving transparency in our prediction
      market activities. It’s important to understand that while we aim for
      accuracy, we can’t guarantee it due to factors like network congestion or
      disruptions in external data sources. The Users should acknowledge the
      risks involved in prediction market activities. By using our Platform, you
      agree to that we bear no liability for any potential losses resulting from
      the use of Chainlink’s price data.
      <Heading
        as='h2'
        mt='5'
        mb='4'
        size='7'
      >
        YOUR USE OF THE PLATFORM
      </Heading>
      <Heading
        as='h3'
        mt='4'
        mb='3'
        size='5'
      >
        Eligibility
      </Heading>
      You must be at least 18 years old (or the age of majority in your
      jurisdiction) to access, use, or register with the Platform. The Company
      does not knowingly collect or maintain information from children under
      this age. We may implement additional eligibility criteria at our
      discretion to comply with applicable laws and regulations.
      <Heading
        as='h3'
        mt='4'
        mb='3'
        size='5'
      >
        Registration and Profiles
      </Heading>
      The Users can create an account on the Platform through verified channels,
      including Twitter, Discord, and other available at the time of signing up.
      During the registration process, users are required to provide personal
      information such as their full name, date of birth, bio, and avatar. All
      provided data is securely stored and managed in compliance with data
      protection standards. You are solely responsible for maintaining the
      confidentiality of your account and password and for restricting access to
      your account. When creating an account through Twitter, you authorize the
      Platform to access certain information from your Twitter account, as
      permitted by Twitter’s Terms of Service and Privacy Policy. When creating
      an account through Discord, you authorize the Platform to access certain
      information from your Discord account, as permitted by Discord’s Terms of
      Service and Privacy Policy. Our Platform offers a chat feature where the
      Users can engage in discussions and interactions. We have implemented
      moderation to monitor for inappropriate language and links. We reserve the
      right to moderate and remove any inappropriate or harmful content,
      including links, to maintain a safe and respectful environment within the
      chat. However, the Users should exercise caution when clicking on links
      shared by others in the chat and understand that they do so at their own
      risk. We are not liable for any damages, losses, or consequences that may
      arise from accessing external websites through links shared in the chat.
      To participate in the prediction market activities, you need to use Web3
      wallet integration. By using your wallet in connection with the Service,
      you agree that you are using that wallet under the terms and conditions of
      the applicable provider of the wallet. Wallets are not operated by,
      maintained by, or affiliated with the Company, and the Company does not
      have custody or control over the contents of your wallet and cannot
      retrieve or transfer its contents. The Users are encouraged to maintain
      the privacy and security of their digital wallets and associated private
      keys. These credentials are used to connect and interact with the
      Platform, and the Users are solely responsible for their safekeeping. The
      Platform will not have access to or store users’ private wallet
      information. The Users are urged to follow best practices for wallet
      security, such as enabling multi-factor authentication and maintaining
      backups of their wallet keys. The Users are responsible for ensuring that
      they comply with all applicable laws and regulations when using the
      Platform, including but not limited to those related to the use of digital
      wallets and blockchain technology. The Users may have the option to remove
      their profiles or disconnect their digital wallets from the Platform at
      any time. Removal of a profile or wallet disconnect will be subject to the
      terms and conditions set forth by the applicable blockchain and wallet
      providers. Xyro will not retain any profile information after a user’s
      profile is removed or disconnected.
      <Heading
        as='h3'
        mt='4'
        mb='3'
        size='5'
      >
        Gas Charge
      </Heading>
      You may be subject to gas charges associated with transactions conducted
      on the Platform. Gas charges are determined by the Ethereum network and
      may vary based on network congestion and transaction complexity. The Users
      are responsible for covering any applicable gas charges incurred during
      their interactions with the Platform.
      <Heading
        as='h3'
        mt='4'
        mb='3'
        size='5'
      >
        Accessing the Services
      </Heading>
      By attempting to acquire or use any Digital Assets, you hereby represent,
      covenant, and warrant to Us as follows:
      <ol>
        <li>
          You are not a U.S. Person, and no directed selling efforts were made
          in the United States, and you are not acquiring the Digital Assets for
          the account or benefit of any U.S. Person. Neither you nor any person
          acting on your behalf has undertaken or carried out any activity for
          the purpose of, or that could reasonably be expected to have the
          effect of, conditioning the market in the United States for the
          Digital Assets. You agree not to cause any advertising of the Digital
          Assets to be published in any publication or posted in any public
          space relating to the Digital Assets in the United States.
        </li>
        <li>
          YOU ARE NOT A CITIZEN OR RESIDENT OF ANY OF THE FOLLOWING
          JURISDICTIONS: Afghanistan, Algeria, Bangladesh, Bolivia, Botswana,
          Burundi, Cambodia, Democratic Republic of Congo, Egypt, Ethiopia,
          European Union, Iran, Iraq, Lebanon, Lesotho, Liberia, Libya, Mali,
          North Korea, Pakistan, South Sudan, Sri Lanka, Sudan, Syria, United
          States of America, Puerto Rico, Virgin Islands or any US Dependent
          Territories, Yemen, Anguilla, Cayman Islands, Saint Vincent and the
          Grenadines, Saint Lucia, Zimbabwe, and other countries or territories
          under the sanctions of the United Nations or the European Union.
        </li>
      </ol>
      Please note that the list of restricted jurisdictions may change over
      time, and it is your responsibility to ensure compliance with applicable
      laws and regulations in your jurisdiction before using the Website.
      <Heading
        as='h3'
        mt='4'
        mb='3'
        size='5'
      >
        User Eligibility and Geographical Restrictions
      </Heading>
      You acknowledge and agree that access to and use of our service is subject
      to the following conditions: Country restrictions: You are solely
      responsible for verifying whether you are permitted to engage in
      activities provided on the Platform in accordance with applicable law of
      your place of residency. VPN and proxy usage: You further agree not to use
      virtual private networks (VPNs), proxies, or any other means to circumvent
      these geographical restrictions. Any attempt to do so is a violation of
      these terms and may result in the immediate termination of your access to
      the service. By accessing and using our service, you confirm that you are
      following these geographical restrictions and that you will not attempt to
      bypass them through any unauthorized means.
      <Heading
        as='h3'
        mt='4'
        mb='3'
        size='5'
      >
        License to Use the Platform
      </Heading>
      We grant you a limited, non-exclusive, non-transferable, non-assignable
      license to access and make personal and non-commercial use of the
      downloadable software included in the Platform. You are not allowed to
      resell or commercially use any part of the Platform or its contents and
      materials.
      <Heading
        as='h2'
        mt='5'
        mb='4'
        size='7'
      >
        PLATFORM GUIDELINES
      </Heading>
      Please note that all Users should adhere to these provisions to maintain a
      safe and trustworthy environment for charitable activities on the
      Platform.
      <Heading
        as='h3'
        mt='4'
        mb='3'
        size='5'
      >
        Our Right to Refuse, Terminate, or Suspend Use
      </Heading>
      We retain the express right to refuse or terminate the use of the Platform
      by any individual or entity at our sole discretion, without prior notice,
      and for any reason or no reason at all. In the event of termination, any
      rights, obligations, or indemnity obligations related to pending or
      completed purchases and the use of the Platform or services shall survive
      such termination.
      <Heading
        as='h2'
        mt='5'
        mb='4'
        size='7'
      >
        ASSUMPTION OF RISK
      </Heading>
      You acknowledge and accept:
      <ol>
        <li>
          The inherent risks associated with participating in prediction market
          activities on the Platform.
        </li>
        <li>
          Prediction markets, by nature, involve speculation and uncertainty,
          and the Users may incur financial losses as a result.
        </li>
        <li>
          While Xyro strives to provide accurate and reliable information,
          predictions may not always align with market outcomes, leading to
          potential losses.
        </li>

        <li>
          The Users engaging in high-leverage prediction games, such as the
          x1000 mode, should be especially cautious as it amplifies both
          potential profits and losses.
        </li>
        <li>
          It is essential for users to assess their risk tolerance and financial
          situation before participating in prediction market activities.
        </li>
        <li>
          Xyro encourages users to conduct thorough research and seek
          independent advice to make informed decisions aligned with their
          individual circumstances and objectives.
        </li>
      </ol>
      <Heading
        as='h2'
        mt='5'
        mb='4'
        size='7'
      >
        PROHIBITED ACTIVITIES
      </Heading>
      <Heading
        as='h3'
        mt='4'
        mb='3'
        size='5'
      >
        Prohibited Content and Uses
      </Heading>
      As the User of the Platform, you are responsible for complying with all
      applicable laws in relation You agree that you will not violate any law,
      contract, intellectual property, or other third-party right and that you
      are solely responsible for your conduct and content, while accessing or
      using the Service. You also agree that you will not:
      <ol>
        <li>violate any applicable law; </li>
        <li>
          publish, distribute, or disseminate any unlawful material or
          information;
        </li>

        <li>
          infringe upon any intellectual property rights, including copyrights,
          trademarks, or patents. take any action that imposes an unreasonable
          or disproportionately large load on our infrastructure, or
          detrimentally interferes with or attempts to, intercept, or
          expropriate any system, data, or information;{' '}
        </li>
        <li>
          use the Platform for fraudulent purposes or to deceive others in the
          context of crypto asset prediction markets. enter or make an attempt
          to enter the Platform, or any part of it (including by accessing
          linked platforms, networks, or systems) unauthorized, including by
          password mining and/or by using other User’s information;
        </li>

        <li>
          impersonate any individual or entity or misrepresent your affiliation
          with any person or organization;{' '}
        </li>

        <li>
          design or assist in designing cheats, exploits, automation software,
          bots, hacks, modes, or any other unauthorized third-party software to
          modify or interfere with it;
        </li>

        <li>
          attempt to disable or circumvent any security or access control
          mechanism;
        </li>
      </ol>
      <Heading
        as='h2'
        mt='5'
        mb='4'
        size='7'
      >
        INTELLECTUAL PROPERTY
      </Heading>
      All content and materials provided on Xyro, including but not limited to
      logos, graphics, text, software, and any other assets, are the
      intellectual property of the Company and are protected by applicable
      copyright, trademark, and other intellectual property laws. The Users
      acknowledge and agree that they do not acquire any ownership rights to the
      content and materials provided on the platform. The Company respects the
      intellectual property rights of others and expects its users to do the
      same. If you believe that any content on Xyro infringes upon your
      intellectual property rights, please contact us immediately at
      support@xyro.io.
      <Heading
        as='h2'
        mt='5'
        mb='4'
        size='7'
      >
        UPDATES TO THE PLATFORM
      </Heading>
      We may issue updates or upgrades to the Platform and disable access to the
      Platform for any period or permanently. The Platform is subject to
      scheduled and unscheduled service interruptions. You consent to automatic
      updates or upgrades of the version of the Platform that you are using on
      your device to a new version. You agree that we will not be liable to you
      for any interruption of the Platform. PRIVACY Our Privacy Policy
      https://xyro.io/privacy-policy explains how we collect, use, and protect
      your personal data.
      <Heading
        as='h2'
        mt='5'
        mb='4'
        size='7'
      >
        GENERAL TERMS
      </Heading>
      <Heading
        as='h3'
        mt='4'
        mb='3'
        size='5'
      >
        Links to Third-Party Platforms
      </Heading>
      The Platform may contain links to platforms owned by third parties. Such
      links are provided for informational purposes only. We are not responsible
      for any third-party platforms or their content. When you click on a link
      to, or access and use, a Third-Party Platform, please be aware that you
      are leaving the Platform. You will be subject to the terms and conditions,
      including privacy policies, of the respective third-party website or
      destination. Xyro assumes no responsibility or liability for the content,
      functionality, or services provided by Third-Party Platforms.
      <Heading
        as='h3'
        mt='4'
        mb='3'
        size='5'
      >
        Limitation of Liability
      </Heading>
      The Company is not responsible for any actions, transactions, or
      interactions between the Users on the Platform, including financial
      transactions. We are not liable for any damages, losses, or injuries
      resulting from interactions with third-party websites, applications, or
      materials accessed through our Platform. The Company provides the Service
      “as is” without warranties, and the Users engage with third parties and
      our Platform at their own risk. The Users acknowledge and accept these
      limitations of liability when using the Service.
      <Heading
        as='h3'
        mt='4'
        mb='3'
        size='5'
      >
        Indemnity
      </Heading>
      You agree to defend, indemnify, and hold us harmless from any claim,
      losses, obligations, damages, or expenses arising from your use of or
      access to the Platform, your breach of these Terms, or your violation of
      any law or the rights of a third party. These Terms will continue to be
      effective even if you have stopped using the Platform.
      <Heading
        as='h3'
        mt='4'
        mb='3'
        size='5'
      >
        Governing Law and Resolution of Disputes
      </Heading>
      All claims relating to these Terms or the Platform will be governed by the
      laws of Hong Kong, excluding its conflict-of-laws rules. Any claim will be
      resolved by the final and binding individual (not class) arbitration
      administered by the Hong Kong International Arbitration Centre (“HKIAC”)
      in accordance with the HKIAC Rules for the time being in force, which
      rules are deemed incorporated by reference in this clause. The seat of
      arbitration shall be Hong Kong. You agree that any cause of action arising
      out of or related to the Platform must commence within 1 year after the
      cause of action accrues. Otherwise, such cause of action is permanently
      barred.
      <Heading
        as='h2'
        mt='5'
        mb='4'
        size='7'
      >
        TERM AND TERMINATION
      </Heading>
      You may stop using the Platform or disconnect your wallet at any time. We
      reserve the right to discontinue the Platform at any time without notice.
      We may also terminate or suspend your access to the Platform without
      notice if (a) you violate these Terms or (b) we are required to do so to
      comply with a legal requirement or court order.
      <Heading
        as='h2'
        mt='5'
        mb='4'
        size='7'
      >
        CONTACT INFORMATION
      </Heading>
      If you have any questions about these Terms, you can contact us at
      support@xyro.io.
    </Box>
  )
}
