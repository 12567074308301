import { FC } from 'react'
import { useReactiveVar } from '@apollo/client'
import { Flex, Separator } from '@radix-ui/themes'
import classnames from 'classnames'
import { useLocation } from 'react-router-dom'
import { RadixText, XyroButton } from 'shared/ui'
import { XyroLink } from 'shared/ui/xyro-link'
import { MODE_IDS } from '../constants'
import { getModeByName } from '../get-mode-by-name'
import { mobileNavToggleVar } from '../store/navigation-mobile'
import { ModeType } from '../types'
import { NavigationMobileMenu } from './navigation-mobile-menu'
import styles from '../navigation.module.scss'

export const NavigationMobileModeSelection: FC = () => {
  const isNavLinksOpen = useReactiveVar(mobileNavToggleVar)

  return (
    <Flex
      className={styles.mobileModeSelection}
      direction={'column'}
      width='100%'
      px='4'
    >
      <NavigationMobileMenu />

      <Flex
        gap='1'
        py='4'
        className={classnames(styles.modeSelectionList, {
          [styles.closed]: !isNavLinksOpen
        })}
      >
        {MODE_IDS.map(name => (
          <ModeSelectionButton
            key={name}
            modeId={name}
          />
        ))}
      </Flex>

      <Separator
        size={'4'}
        color='gray'
        my={'2'}
      />
    </Flex>
  )
}

interface ItemProps {
  modeId: ModeType
}
export const ModeSelectionButton: FC<ItemProps> = ({ modeId: modeId }) => {
  const { pathname } = useLocation()
  const selectedMode = pathname.split('/')[1]
  const isModeSelected = modeId === selectedMode

  const mode = getModeByName(modeId)
  const ModeIcon = mode.icon
  const modeName = mode.name

  return (
    <Flex
      direction={'column'}
      align={'center'}
      gap='1'
      grow={'1'}
    >
      <XyroButton
        value={modeId}
        size='4'
        className={classnames(styles.modeSelectionItem, {
          [styles.active]: isModeSelected,
          [styles[modeId]]: isModeSelected
        })}
      >
        <XyroLink to={`/${modeId}`}>
          <Flex
            align='center'
            gap='2'
            px='2'
          >
            <ModeIcon
              className={'navigationLinkIcon'}
              width={'4.5rem'}
              height={'4.5rem'}
              color='var(--gray)'
            />
          </Flex>
        </XyroLink>
      </XyroButton>

      <RadixText
        size={'1'}
        weight={isModeSelected ? 'medium' : 'light'}
      >
        {modeName}
      </RadixText>
    </Flex>
  )
}
