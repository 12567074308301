import { useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useLoadBalance } from 'contracts/usdc'
import { balanceVar } from 'shared/store/balance-store'
import { globalOverlayBalanceLoadingVar } from 'shared/store/global-overlay-state-store'

export const useBalanceLoader = () => {
  const { balance: loadedBalance, loading, error } = useLoadBalance()

  const balance = useReactiveVar(balanceVar)

  useEffect(() => {
    if (!loadedBalance) return

    balanceVar(Number(loadedBalance))
  }, [loadedBalance])

  useEffect(() => {
    globalOverlayBalanceLoadingVar(loading && !error)
  }, [loading, error])

  return {
    balance,
    loading,
    error: Boolean(error)
  }
}
