import React from 'react'
import { Card, Flex } from '@radix-ui/themes'
import { ColumnDef, createColumnHelper } from '@tanstack/table-core'
import Skeleton from 'react-loading-skeleton'
import { BetsTable } from '../../ui/bets-table/components/bets-table'
import { TableItemXyroTextSkeleton } from '../../ui/bets-table/components/table-items/table-item-xyro-token-text-skeleton'
import styles from './skeletons.module.scss'

export const TableSkeleton: React.FC = () => {
  return (
    <Card
      size={'4'}
      style={{ width: '100%' }}
    >
      <Flex
        gap={'6'}
        align={'center'}
        mb={'6'}
      >
        <Skeleton
          width={'45rem'}
          height={'6rem'}
        />
      </Flex>
      <Flex width={'100%'}>
        <BetsTable<unknown>
          bets={[{}, {}, {}, {}, {}] as unknown[]}
          columns={getColumns() as ColumnDef<unknown, any>[]}
          pageSize={5}
          className={styles.tableSkeletonContainer}
        />
      </Flex>
    </Card>
  )
}

const columnHelper = createColumnHelper<unknown>()
// columnHelper.accessor('pnl', {
//   header: 'Profit',
//   cell: props => <TableItemProfit value={props.getValue()} />
// })

const getColumns = () => [
  columnHelper.accessor(item => item, {
    id: '1',
    cell: () => {
      return <TableItemXyroTextSkeleton isXyroTokenDisplayed={false} />
    },
    header: () => (
      <Skeleton
        width={'16rem'}
        height={'3rem'}
      />
    )
  }),

  columnHelper.accessor(item => item, {
    id: '2',
    header: () => (
      <Skeleton
        width={'16rem'}
        height={'3rem'}
      />
    ),
    cell: () => {
      return <TableItemXyroTextSkeleton />
    }
  }),

  columnHelper.accessor(item => item, {
    id: '3',
    header: () => (
      <Skeleton
        width={'16rem'}
        height={'3rem'}
      />
    ),
    cell: () => {
      return <TableItemXyroTextSkeleton />
    }
  }),

  columnHelper.accessor(item => item, {
    id: '4',
    header: () => (
      <Skeleton
        width={'16rem'}
        height={'3rem'}
      />
    ),
    cell: () => {
      return <TableItemXyroTextSkeleton isXyroTokenDisplayed={false} />
    }
  })
]
