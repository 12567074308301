import { makeVar } from '@apollo/client'
import { SendGlobalRoomMessageMutation } from '__generated__/graphql'

export const currentChatMessageVar = makeVar<string>('')

export const globalChatMessagesVar = makeVar<
  SendGlobalRoomMessageMutation['sendMessage'][]
>([])
export const globalChatMessagesSkipAmountVar = makeVar<number>(0)
export const newMessageLoadingVar = makeVar(false)
