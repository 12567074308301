/* eslint-disable max-statements */
import { useReactiveVar } from '@apollo/client'
import { Flex, Text } from '@radix-ui/themes'
import { DataTestIDs } from 'shared/constants'
import { useWallet } from 'shared/hooks/use-wallet'
import { WalletIcon } from 'shared/icons'
import { userVar } from 'shared/store/user'
import { useAllowanceLoader } from '../hooks/use-allowance-loader'
import { useBalanceLoader } from '../hooks/use-balance-loader'
import { useTreasuryLoader } from '../hooks/use-treasury-loader'
import { isOpenAllowanceDialogVar } from '../store/dialogs'
import { ApprovedBalanceContent } from './approved-balance-content'
import styles from '../approved-balance.module.scss'

export const ApprovedBalance = () => {
  const user = useReactiveVar(userVar)
  const { isReady } = useWallet()

  useAllowanceLoader()
  const { balance } = useBalanceLoader()
  const { balance: treasuryBalance } = useTreasuryLoader()

  const onClickOpenDialog = () => {
    isOpenAllowanceDialogVar(true)
  }

  const fullFormattedBalance = `${(balance + treasuryBalance).toFixed(2)} USDT`

  if (!user || !isReady) return null

  return (
    <>
      <Flex
        direction={'column'}
        className={styles.approvePopoverTrigger}
        onClick={onClickOpenDialog}
        data-testid={DataTestIDs.buttonApprovedBalanceHeader}
        p={'2'}
      >
        <Text
          className='color-white'
          size={'1'}
        >
          Balance:
        </Text>
        <Flex
          justify={'center'}
          gap={'1'}
        >
          <WalletIcon />
          <Text
            className={styles.balanceValue}
            data-testid={DataTestIDs.balanceValueHeader}
          >
            {fullFormattedBalance}
          </Text>
        </Flex>
      </Flex>
      <ApprovedBalanceContent />
    </>
  )
}
