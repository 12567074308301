import { FormEvent, useCallback } from 'react'
import { useReactiveVar } from '@apollo/client'
import { getFormValues } from 'shared/utils/get-form-values'
import { BALANCE_CLAIM_SWITCH_TYPES } from '../constants'
import { activeBalanceSwitchTypeVar } from '../store/switch-types'
import { ApporveBalanceFormValues } from '../types'
import { useApproveSubmit } from './use-approve-submit'
import { useClaimSubmit } from './use-claim-submit'

export const useApproveBalanceFormSubmit = () => {
  const { loading: loadingApproveSubmit, handleApprove } = useApproveSubmit()
  const { loading: loadingClaimSubmit, handleClaim } = useClaimSubmit()
  const activeType = useReactiveVar(activeBalanceSwitchTypeVar)

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      const formValues = getFormValues<
        ApporveBalanceFormValues,
        ApporveBalanceFormValues
      >(event.target as HTMLFormElement)

      if (activeType === BALANCE_CLAIM_SWITCH_TYPES.Approve) {
        handleApprove(formValues.amount)
      } else {
        handleClaim(formValues.amount)
      }
    },
    [activeType, handleApprove, handleClaim]
  )

  const loading =
    activeType === BALANCE_CLAIM_SWITCH_TYPES.Approve ?
      loadingApproveSubmit
    : loadingClaimSubmit

  return {
    loading,
    handleSubmit
  }
}
