import { MODE_ROUTES } from 'features/navigation/constants'
import { ModeType } from 'features/navigation/types'

export function getCurrentModeByPathName(pathname: string) {
  const currentRoute = pathname.split('/')[1]
  const currentMode = MODE_ROUTES.includes(currentRoute as ModeType)
    ? (currentRoute as ModeType)
    : null

  return currentMode
}
