import { BetDirectionType } from 'shared/types'

export const DIRECTION_OPTIONS = [
  {
    value: BetDirectionType.UP,
    label: 'Up'
  },
  {
    value: BetDirectionType.DOWN,
    label: 'Down'
  }
]
