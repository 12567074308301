import { useCallback, useEffect, useState } from 'react'
import { useReactiveVar } from '@apollo/client'

import { useUsdc } from 'contracts/usdc'
import { formatWeb3Price } from 'shared/utils/format-web3-price'
import { userVar } from 'shared/store/user'
import { useAlloanceEvents } from 'contracts/usdc/hooks/use-allowance-events'

export const useLoadAllowance = () => {
  const [loading, setLoading] = useState(true)
  const [allowance, setAllowance] = useState(0)
  const user = useReactiveVar(userVar)

  // TODO: temprary disable subscription
  // const { event } = useAlloanceEvents()

  const usdc = useUsdc()

  const loadAllowance = useCallback(async () => {
    if (!usdc || !user) {
      setLoading(false)
      return
    }

    try {
      const result = await usdc.getAllowence()
      setAllowance(formatWeb3Price(result))
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [usdc, setAllowance])

  useEffect(() => {
    loadAllowance()
  }, [usdc])

  // useEffect(() => {
  //   loadAllowance()
  // }, [event])

  return {
    allowance,
    loading
  }
}
