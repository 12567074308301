import { useEffect } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useLoadAllowance } from 'features/auth/hooks/use-load-allowance'
import { globalOverlayAllowanceLoadingVar } from 'shared/store/global-overlay-state-store'
import { transactionAllowanceAmountVar } from 'shared/store/transaction-allowance-amount'

export const useAllowanceLoader = () => {
  const { allowance: loadedAllowance, loading: loadingAllowance } =
    useLoadAllowance()

  const allowance = useReactiveVar(transactionAllowanceAmountVar)

  useEffect(() => {
    if (!loadedAllowance) return

    transactionAllowanceAmountVar(loadedAllowance)
  }, [loadedAllowance])

  useEffect(() => {
    globalOverlayAllowanceLoadingVar(loadingAllowance)
  }, [loadingAllowance])

  return { allowance }
}
