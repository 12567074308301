import { WithdrawPercentageItem } from './types'

export const WITHDRAW_PERCENTAGE: WithdrawPercentageItem[] = [
  {
    lable: '5%',
    value: 5
  },
  {
    lable: '10%',
    value: 10
  },
  {
    lable: '50%',
    value: 50
  },
  {
    lable: '100%',
    value: 100
  }
]
