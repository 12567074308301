import { Maybe } from 'shared/types'
import { showNotificationToast } from 'shared/utils/notify'
import { WIZARD_STEPS_BY_MODE_MAP as WIZARD_STEPS_BY_MODE_MAP } from '../constants'
import { WizardFlowType, WizardMode, WizardStep } from '../types'

// eslint-disable-next-line max-statements, complexity
export function getWizardNextStep(
  wizardMode: Maybe<WizardMode>,
  flowType: WizardFlowType,
  currentStep: WizardStep,
  hasNft?: Maybe<boolean>
) {
  if (wizardMode === null) return null

  const isNftNotExist =
    hasNft === false &&
    flowType === WizardFlowType.web3Nft &&
    currentStep === WizardStep.nftCheck
  if (isNftNotExist) {
    notifyOnError(
      'NFT was not found in your account! Enter your referral code please'
    )

    return WizardStep.referral
  }

  const currentStepsMap = WIZARD_STEPS_BY_MODE_MAP?.[wizardMode]?.[flowType]

  if (!currentStepsMap) return null

  const currentStepIndex = currentStepsMap?.indexOf(currentStep)

  if (currentStepIndex === -1) return currentStepsMap?.[0] || null

  const nextStep = currentStepsMap?.[currentStepIndex + 1]

  if (!nextStep) return null

  return nextStep
}

const notifyOnError = (message: string) => {
  showNotificationToast({
    description: message,
    title: 'Error!',
    type: 'warning',
    duration: 5000
  })
}
