import { Flex, Separator, Text } from '@radix-ui/themes'
import cn from 'classnames'
import { WELCOME_WINDOW_STEPS_DATA } from 'shared/constants/welcome-window'
import styles from './beta-welcome-message-modal.module.scss'

interface Props {
  step: 'step1' | 'step2' | 'step3'
}
export const BetaWelcomeMessageModalStep: React.FC<Props> = ({ step }) => {
  const stepData = WELCOME_WINDOW_STEPS_DATA[step]

  return (
    <Flex
      className={styles.welcomeWindowStepContainer}
      direction={'column'}
      width={'100%'}
    >
      <img
        className={styles.welcomeWindowStepImage}
        src={stepData.image}
        alt={step}
      />

      <Flex
        direction={'column'}
        gap={'2'}
      >
        <Text
          className='color-gray'
          weight={'regular'}
          size={'2'}
        >
          {stepData.index}
        </Text>

        <Text
          className='color-white'
          weight={'bold'}
          size={'4'}
        >
          {stepData.title}
        </Text>

        <Text
          className='color-white'
          weight={'light'}
          size={'1'}
        >
          {stepData.description}
        </Text>
      </Flex>

      <Separator
        size={'4'}
        my={'1'}
      />

      <Flex
        direction={'column'}
        gap={'2'}
      >
        <Text
          className={cn('color-white', styles.welcomeWindowStepSecondaryTitle)}
          weight={'bold'}
          size={'2'}
        >
          {stepData.secondaryTitle}
        </Text>

        <Text
          className='color-white'
          weight={'medium'}
          size={'1'}
        >
          {stepData.secondaryDescription}
        </Text>

        {step === 'step1' ?
          <Text
            className='color-white'
            weight={'medium'}
            size={'1'}
          >
            2. Alternatively, learn other ways to obtain these tokens in{' '}
            <a
              href='https://mirror.xyz/xyro.eth/8ZcK1uPx1j5_7G4lmbln11tC6un7RHKM2v9ulZ4BX4A'
              target='_blank'
              rel='noreferrer'
              className={styles.articleLink}
            >
              this article.
            </a>
          </Text>
        : null}
      </Flex>
    </Flex>
  )
}
